import { CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCompletedOrderDetails } from "../../../services/Collection";
import {
  BoxContainer,
  LoadingWrapper,
  SubHeader,
  AddressWrapper,
  Header,
} from "../../../style/Gobalstyle";
import { getErrorMessage } from "../../../Utils/ErrorMessage";
import IntlMessage from "../../../Utils/IntlMessage";
import FeedbackTable from "../../Feedback/FeedbackTable";
import {
  CompletedDetailWrapper,
  LeftSection,
  PrintReceipts,
  RightSection,
} from "../OrderStyle";
import CompletedPrintReceipt from "./CompletedPrintReceipt";
import { ShowFieldValue } from "../../../Utils/commanMethods";
import styled from "styled-components";

export default function CompletedShowDetails() {
  const [loading, setLoading] = useState(false);
  const [completedData, setCompletedData] = useState(null);
  const [itemDetails, setItemDetails] = useState([]);
  const params = useParams();
  const Currency = useSelector(
    (state) => state?.profileDetails?.restaurantDetails?.currency
  );
  const [openReceipt, setOpenReceipt] = useState(false);

  const UserHeader = [
    {
      id: <IntlMessage id="Order.orderDeatils.name" />,
      key: "customer_name",
    },
    {
      id: <IntlMessage id="Order.orderDeatils.phoneNumber" />,
      key: "phone_number",
    },
    {
      id: <IntlMessage id="Order.completed.orderNumber.itemSummery" />,
      key: "item_name",
    },
    {
      id: <IntlMessage id="Order.completed.orderNumber.qty" />,
      key: "quantity",
    },
    {
      id: <IntlMessage id="Order.completed.orderNumber.price" />,
      key: "price",
    },
    {
      id: <IntlMessage id="Order.completed.orderNumber.subTotal" />,
      key: "sub_total",
    },
  ];

  const TableAction = {
    apply: false,
  };

  const getOrderDetails = async () => {
    setLoading(true);
    let res = await getCompletedOrderDetails(params.id);
    if (res.status === 200) {
      console.log("res.data", res.data);
      setCompletedData(res.data);
      setItemDetails(res.data.OrderItems);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrderDetails();
  }, []);

  let pay_mode = {
    1: <IntlMessage id="Order.completed.cash" />,
    2: <IntlMessage id="Order.completed.card" />,
    3: <IntlMessage id="Order.completed.aglut" />,
    4: <IntlMessage id="Order.completed.multiple" />,
    5: <IntlMessage id="Order.completed.cashOnDelivery" />,
    7: <IntlMessage id="Order.completed.cashOnArrival" />,
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress sx={{ color: "#f55a2c" }} />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      {openReceipt && (
        <CompletedPrintReceipt
          open={openReceipt}
          handleClose={() => setOpenReceipt(false)}
          subOrderData={completedData}
        />
      )}

      <SubHeader>
        <p>
          <IntlMessage id="Order.completed.orderNumber.heading" /> #
          {completedData?.id}
        </p>
      </SubHeader>
      <BoxContainer>
        <FeedbackTable
          header={UserHeader}
          tableData={itemDetails}
          action={TableAction}
        />
      </BoxContainer>
      <BoxContainer>
        <CompletedDetailWrapper>
          <LeftSection>
            {completedData?.order_type === "Takeaway" && (
              <OrderTypeBold>
                <label>
                  <IntlMessage id="Orders.createOrder.orderType" />:
                </label>
                <span>
                  {completedData?.mode_of_payment == 7
                    ? "Cash on arrival"
                    : "Paid"}
                </span>
              </OrderTypeBold>
            )}
            <div>
              <label>
                <IntlMessage id="Order.completed.orderNumber.orderNo" />:
              </label>
              <span>{completedData?.id}</span>
            </div>

            <div>
              <label>
                <IntlMessage id="Orders.Status" />:
              </label>
              <span>Order Completed</span>
            </div>

            {completedData?.order_type === "Dine In" ? (
              <div>
                <label>
                  <IntlMessage id="Order.completed.orderNumber.dineIn" />:
                </label>
                <span>{completedData?.QrCode?.name}</span>
              </div>
            ) : (
              <div>
                <label>
                  <IntlMessage id="qrMenu.qrMenuGroups.createMenuGroups.Takeaway" />
                  :
                </label>
                <span>{completedData?.QrCode?.name}</span>
              </div>
            )}
            <div>
              <label>
                <IntlMessage id="Order.completed.orderNumber.tip" />:
              </label>
              <span>{completedData?.tip}</span>
            </div>

            <div>
              <label>
                <IntlMessage id="Order.orderDeatils.specificAmount" />:
              </label>
              <span>{completedData?.specific_payment_amount}</span>
            </div>

            <div>
              <label>
                <IntlMessage id="Order.orderDeatils.partialAmount" />:
              </label>
              <span>{completedData?.partial_payment_amount}</span>
            </div>

            <div>
              <label>
                <IntlMessage id="Order.orderDeatils.discount" />:
              </label>
              <span>{completedData?.discount}</span>
            </div>
            {completedData?.order_type == "Delivery" && (
              <div>
                <label>
                  <IntlMessage id="orderDetails.delivery.fee" />:
                </label>
                <span>
                  {Currency} {completedData?.delivery_fee}
                </span>
              </div>
            )}
          </LeftSection>
          <RightSection>
            <div>
              <label>
                <IntlMessage id="Order.completed.orderNumber.subTotal" />:
              </label>
              <span>
                {Currency} {completedData?.sub_total}
              </span>
            </div>

            <div>
              <label>
                <IntlMessage id="profileSection.vat" />:
              </label>
              <span>
                {Currency} {completedData?.vat}
              </span>
            </div>

            <div>
              <label>
                <IntlMessage id="profileSection.serviceCharge" />:
              </label>
              <span>
                {Currency} {completedData?.service_tax}
              </span>
            </div>

            <div>
              <label style={{ fontSize: "20px" }}>
                <IntlMessage id="Order.completed.orderNumber.total" />:
              </label>
              <span style={{ fontSize: "20px" }}>
                {Currency} {completedData?.total + completedData?.delivery_fee}
              </span>
            </div>

            <div>
              <label>
                <IntlMessage id="Order.completed.modeOfPayment" />:
              </label>
              <span>
                {pay_mode[completedData?.mode_of_payment] || "---"}
              </span>

              {/* {completedData?.mode_of_payment === "1" ? (
                <span>
                  <IntlMessage id="Order.completed.cash" />
                </span>
              ) : completedData?.mode_of_payment === "2" ? (
                <span>
                  <IntlMessage id="Order.completed.card" />
                </span>
              ) : completedData?.mode_of_payment === "3" ? (
                <span>
                  <IntlMessage id="Order.completed.aglut" />
                </span>
              ) :
                completedData?.mode_of_payment === "4" ? (
                  <span>
                    <IntlMessage id="Order.completed.multiple" />
                  </span>
                )
              :(
                completedData?.mode_of_payment === "5" && (
                  <span>
                    <IntlMessage id="Order.completed.cashOnDelivery" />
                  </span>
                )
              )
              } */}
            </div>

            <PrintReceipts onClick={() => setOpenReceipt(true)}>
              <IntlMessage id="button.printReceipts" />
            </PrintReceipts>
          </RightSection>
        </CompletedDetailWrapper>
      </BoxContainer>
      {completedData?.order_type === "Delivery" && completedData?.Address && (
        <BoxContainer>
          <Header>
            <header>
              <IntlMessage id="Order.orderDeatils.customerAddress" />
            </header>
          </Header>
          <hr />
          <LeftSection>
            <ShowFieldValue
              field="Order.orderDeatils.houseNumber"
              value={completedData?.Address["house_number"]}
            />
            <ShowFieldValue
              field="Order.orderDeatils.street"
              value={completedData?.Address["street"]}
            />
            <ShowFieldValue
              field="Order.orderDeatils.city"
              value={completedData?.Address["city"]}
            />
            <ShowFieldValue
              field="Order.orderDeatils.country"
              value={completedData?.Address["country"]}
            />
          </LeftSection>
        </BoxContainer>
      )}
    </div>
  );
}

const OrderTypeBold = styled.div`
  label,
  span {
    font-size: 18px !important;
    color: red !important;
  }
`;