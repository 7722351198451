import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import {
  BlackButtonMobile,
  Cancel,
  OrangeButton,
} from "../../../style/Gobalstyle";
import moment from "moment/moment";
import IntlMessage from "../../../Utils/IntlMessage";

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: "20px 10px",
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    orderId: {
      fontSize: "24px",
      fontWeight: "700",
    },
    restaurentName: {
      fontSize: "21px",
      fontWeight: "500",
    },
    address: {
      fontSize: "12px",
      fontWeight: "400",
      textTransform: "uppercase",
      margin: "5px 0",
      padding: "0 40px",
    },
  },
  phase: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "uppercase",
    margin: "10px 0 0 0",
  },
  headerPhase: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "uppercase",
    margin: "10px 0 0 0",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    fontWeight: "400",
    margin: "10px 0 0 0",
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",

    ID: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "2px 5px",
    },
  },
  resTable: {
    width: "100%",
    color: "#000000",
    header: {
      width: "100%",
      height: "42px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "6px",
      backgroundColor: "#e2e2e2",
      padding: "0 10px",
      margin: "12px 0",
      InfoRow1: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
      InfoRow2: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
      InfoRow3: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
      InfoRow4: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
      Row1: {
        width: "100px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },
      Row2: {
        width: "75px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row3: {
        width: "100px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row4: {
        width: "75px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row5: {
        width: "40px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row6: {
        width: "60px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row7: {
        width: "60px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row8: {
        width: "40px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    addons: {
      display: "block",
      color: "#f55a2c",
      fontSize: "9px",
      fontWeight: "400",
    },
    special_notes: {
      display: "block",
      color: "#ff0000",
      fontSize: "10px",
      fontWeight: "400",
    },

    body: {
      width: "100%",
      minHeight: "42px",
      maxHeight: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "6px",
      backgroundColor: "#e2e2e24a",
      padding: "5px 10px",
      margin: "5px 0",

      InfoRow1: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
      InfoRow2: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
      InfoRow3: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
      InfoRow4: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },

      Row1: {
        width: "100px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },
      Row2: {
        width: "75px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row3: {
        width: "100px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row4: {
        width: "75px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row5: {
        width: "40px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row6: {
        width: "60px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row7: {
        width: "60px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
      },

      Row8: {
        width: "40px",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        Text1: {
          color: "#008000",
        },
        Text2: {
          color: "#fe0202",
        },
      },
    },
  },

  headerTable: {
    width: "100%",
    color: "#000000",
    header: {
      width: "100%",
      height: "42px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "6px",
      backgroundColor: "#e2e2e2",
      padding: "0 10px",
      margin: "20px 0 2px 0",
      InfoRow1: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
      InfoRow2: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
      InfoRow3: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
      InfoRow4: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
      },
    },

    body: {
      width: "100%",
      minHeight: "42px",
      maxHeight: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "6px",
      backgroundColor: "#e2e2e24a",
      padding: "5px 10px",
      margin: "2px 0 20px 0",

      InfoRow1: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
      InfoRow2: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
      InfoRow3: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
      InfoRow4: {
        width: "20%",
        margin: "0 2px",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
    },
  },

  PriceSection: {
    textAlign: "left",
    margin: "20px 0 ",
  },
  totalPrice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "0 10px",
    fontSize: "13px",
    fontWeight: "500",
    margin: "2px 0",
    textAlign: "left",
  },
  grandTotal: {
    display: "flex",
    alignItems: "start",
    padding: "10px",
    fontSize: "15px",
    fontWeight: "700",
    margin: "2px 0",
  },

  line: {
    display: "flex",
    width: "98%",
    height: "1px",
    backgroundColor: "#0000004f",
    margin: "0 auto",
  },
  billdetails: {
    width: "100%",
    fontSize: "18px",
    fontWeight: "500",
    padding: "0 10px",
    display: "flex",
    margin: "40px 0 0 0",
  },

  info: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "30px 0 0 0",

    innerWapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",

      label: {
        fontSize: "11px",
        fontWeight: "500",
        textTransform: "uppercase",
        margin: "10px 0 0 0",
      },
      span: {
        fontSize: "12px",
        fontWeight: "400",
      },
    },
  },

  logoImage: {
    width: "60px",
    height: "60px",
    objectFit: "fit",
  },
});

const MyDoc = ({ subOrderData, getURL }) => {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;

  const Language = useSelector((state) => state?.languageDirection?.language);
  const RestaurentDetails = useSelector(
    (state) => state?.profileDetails?.restaurantDetails
  );
  const CurrentDateTime = new Date();

  let image =
    `${aqlutstorage}` + `${containerProfile}` + `${RestaurentDetails?.logo}`;

  console.log("subOrderData", subOrderData?.QrCode?.name);

  return (
    <>
      <Document>
        <Page
          size="A4"
          style={styles.body}
          bookmark="Harry Potter and the Philosopher's Stone"
        >
          <Image
            style={styles.logoImage}
            src={`https://corsproxy.io/?${image}`}
          />
          <View style={styles.header}>
            <Text style={styles.header.orderId}>
              Order Number #{subOrderData?.id}
            </Text>

            <Text style={styles.header.restaurentName}>
              {Language === "en"
                ? RestaurentDetails?.name
                : RestaurentDetails?.ar_name}
            </Text>
            <Text style={styles.header.address}>
              {RestaurentDetails?.address}
            </Text>
          </View>



          <View style={styles.headerTable}>
            <View style={styles.headerTable.header}>
              <View style={styles.headerTable.header.InfoRow1}>
                <Text>Date</Text>
              </View>
              <View style={styles.headerTable.header.InfoRow2}>
                <Text>Time</Text>
              </View>
              <View style={styles.headerTable.header.InfoRow3}>
                <Text>Order Type</Text>
              </View>
              <View style={styles.headerTable.header.InfoRow4}>
                <Text>QR Code Name</Text>
              </View>
            </View>

            <View>
              <View style={styles.headerTable.body}>
                <View style={styles.headerTable.body.InfoRow1}>
                  <Text>
                    {moment(CurrentDateTime).format("dddd")}{" "}
                    {moment(CurrentDateTime).format("DD-MM-YYYY")}
                  </Text>
                </View>

                <View style={styles.headerTable.body.InfoRow2}>
                  <Text>{moment(CurrentDateTime).format("h:mm a")}</Text>
                </View>

                <View style={styles.headerTable.body.InfoRow3}>
                  <Text>{subOrderData?.order_type} </Text>
                </View>

                <View style={styles.headerTable.body.InfoRow4}>
                  <Text>{subOrderData?.QrCode?.name} </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.resTable}>
            <View style={styles.resTable.header}>
              <View style={styles.resTable.header.Row1}>
                <Text>Name</Text>
              </View>
              <View style={styles.resTable.header.Row2}>
                <Text>Mobile</Text>
              </View>
              <View style={styles.resTable.header.Row3}>
                <Text>Item</Text>
              </View>
              <View style={styles.resTable.header.Row5}>
                <Text>QTY.</Text>
              </View>
              <View style={styles.resTable.header.Row6}>
                <Text>Amount</Text>
              </View>
              <View style={styles.resTable.header.Row7}>
                <Text>Subtotal</Text>
              </View>
            </View>

            {subOrderData?.OrderItems?.map((el, index) => (
              <View>
                <View style={styles.resTable.body}>
                  <View style={styles.resTable.body.Row1}>
                    <Text style={styles.resTable.body.Row1.Text}>
                      {el?.customer_name}
                    </Text>
                  </View>

                  <View style={styles.resTable.body.Row2}>
                    <Text style={styles.resTable.body.Row2.Text}>
                      {el?.phone_number}
                    </Text>
                  </View>

                  <View style={styles.resTable.body.Row3}>
                    <Text style={styles.resTable.body.Row3.Text}>
                      {el?.item_name}
                    </Text>

                    {el?.OrderItemAddOns &&
                      el?.OrderItemAddOns.map((addons) => (
                        <View style={styles.resTable.addons}>
                          <Text>
                            {addons?.option_group_name} (
                            {addons?.option_item_name}){" "}
                          </Text>
                        </View>
                      ))}

                    <Text style={styles.resTable.special_notes}>
                      {el?.special_notes}
                    </Text>
                  </View>

                  <View style={styles.resTable.body.Row5}>
                    <Text style={styles.resTable.body.Row5.Text}>
                      {el?.quantity}
                    </Text>
                  </View>

                  <View style={styles.resTable.body.Row6}>
                    <Text style={styles.resTable.body.Row6.Text}>
                      {el?.price}
                    </Text>
                  </View>

                  <View style={styles.resTable.body.Row7}>
                    <Text style={styles.resTable.body.Row7.Text}>
                      {el?.sub_total}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>

          <View style={styles.billdetails}>
            <Text>Bill Details</Text>
          </View>
          <View>
            <Text style={styles.line}></Text>
          </View>

          <View style={styles.PriceSection}>
            <View style={styles.totalPrice}>

              {subOrderData?.order_type === "Takeaway" && (
                <Text style={{ color: "red", fontSize: "15px" }}>
                  Order Type -{" "}
                  {subOrderData?.mode_of_payment == 7
                    ? "Cash on arrival"
                    : "Paid"}
                </Text>
              )}

              {subOrderData?.order_type === "Delivery" && (
                <Text style={{
                  color: "red",
                  fontSize: "15px",
                }}>
                  Order Type -{" "}
                  {subOrderData?.mode_of_payment == 5
                    ? "Cash on delivery"
                    : "Paid"}
                </Text>
              )}



              <Text>Item Total - {subOrderData?.sub_total}</Text>
              <Text>Tips - {subOrderData?.tip}</Text>
              <Text>Discount - {subOrderData?.discount}</Text>

              <Text>Loyalty Discount - {subOrderData?.loyalty_points}</Text>

              <Text>Pending Payment - {subOrderData?.service_tax}</Text>

              {subOrderData?.order_type === "Delivery" &&
                <Text>Delivery fee - {subOrderData?.delivery_fee ?? 0}</Text>
              }

            </View>

            <View style={styles.grandTotal}>

              {subOrderData?.order_type === "Delivery" ?
                <View style={styles.grandTotal}>
                  <Text>Total Amount - {parseFloat(subOrderData?.total) + parseFloat(subOrderData?.delivery_fee)}</Text>
                </View>
                :
                <View style={styles.grandTotal}>
                  <Text>Total Amount - {subOrderData?.total}</Text>
                </View>
              }
            </View>
          </View>

          <View style={styles.billdetails}>
            <Text>Customer Address Detail</Text>
          </View>
          <View>
            <Text style={styles.line}></Text>
          </View>

          <View style={styles.PriceSection}>
            <View style={styles.totalPrice}>
              <Text>House No - {subOrderData?.Address?.house_number}</Text>
              <Text>Street - {subOrderData?.Address?.street}</Text>
              <Text>City - {subOrderData?.Address?.city}</Text>
              <Text>
                Country - {subOrderData?.Address?.country}
              </Text>
            </View>
          </View>


          {subOrderData?.OrderCarDetail && (
            <>
              <View style={styles.billdetails}>
                <Text>Car Details</Text>
              </View>
              <View>
                <Text style={styles.line}></Text>
              </View>

              <View style={styles.PriceSection}>
                <View style={styles.totalPrice}>
                  <Text>
                    Car Plate Number - {subOrderData?.OrderCarDetail.car_number}
                  </Text>
                  <Text>Car Type - {subOrderData?.OrderCarDetail.car_type}</Text>
                  <Text>
                    Car Colour - {subOrderData?.OrderCarDetail.car_colour}
                  </Text>
                </View>
              </View>
            </>
          )}

          {subOrderData?.OrderOfficeDetail && (
            <>
              <View style={styles.billdetails}>
                <Text>Office/Room Details</Text>
              </View>
              <View>
                <Text style={styles.line}></Text>
              </View>

              <View style={styles.PriceSection}>
                <View style={styles.totalPrice}>
                  <Text>
                    Floor Number -{" "}
                    {subOrderData?.OrderOfficeDetail?.floor_number}
                  </Text>
                  <Text>
                    Office/Room Number -{" "}
                    {subOrderData?.OrderOfficeDetail?.room_number}
                  </Text>
                </View>
              </View>
            </>
          )}

        </Page>

      </Document>

      <PDFDownloadLink
        document={
          <Document>
            <Page
              size="A4"
              style={styles.body}
              bookmark="Harry Potter and the Philosopher's Stone"
            >
              <Image
                style={styles.logoImage}
                src={`https://corsproxy.io/?${image}`}
              />
              <View style={styles.header}>
                <Text style={styles.header.orderId}>
                  Order Number #{subOrderData?.id}
                </Text>

                <Text style={styles.header.restaurentName}>
                  {Language === "en"
                    ? RestaurentDetails?.name
                    : RestaurentDetails?.ar_name}
                </Text>
                <Text style={styles.header.address}>
                  {RestaurentDetails?.address}
                </Text>
              </View>

              <View style={styles.headerTable}>
                <View style={styles.headerTable.header}>
                  <View style={styles.headerTable.header.InfoRow1}>
                    <Text>Date</Text>
                  </View>
                  <View style={styles.headerTable.header.InfoRow2}>
                    <Text>Time</Text>
                  </View>
                  <View style={styles.headerTable.header.InfoRow3}>
                    <Text>Order Type</Text>
                  </View>
                  <View style={styles.headerTable.header.InfoRow4}>
                    <Text>QR Code Name</Text>
                  </View>
                </View>

                <View>
                  <View style={styles.headerTable.body}>
                    <View style={styles.headerTable.body.InfoRow1}>
                      <Text>
                        {moment(CurrentDateTime).format("dddd")}{" "}
                        {moment(CurrentDateTime).format("DD-MM-YYYY")}
                      </Text>
                    </View>

                    <View style={styles.headerTable.body.InfoRow2}>
                      <Text>{moment(CurrentDateTime).format("h:mm a")}</Text>
                    </View>

                    <View style={styles.headerTable.body.InfoRow3}>
                      <Text>{subOrderData?.order_type} </Text>
                    </View>

                    <View style={styles.headerTable.body.InfoRow4}>
                      <Text>{subOrderData?.QrCode?.name} </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.resTable}>
                <View style={styles.resTable.header}>
                  <View style={styles.resTable.header.Row1}>
                    <Text>Name</Text>
                  </View>
                  <View style={styles.resTable.header.Row2}>
                    <Text>Mobile</Text>
                  </View>
                  <View style={styles.resTable.header.Row3}>
                    <Text>Item</Text>
                  </View>
                  <View style={styles.resTable.header.Row5}>
                    <Text>QTY.</Text>
                  </View>
                  <View style={styles.resTable.header.Row6}>
                    <Text>Amount</Text>
                  </View>
                  <View style={styles.resTable.header.Row7}>
                    <Text>Subtotal</Text>
                  </View>
                </View>

                {subOrderData?.OrderItems?.map((el, index) => (
                  <View>
                    <View style={styles.resTable.body}>
                      <View style={styles.resTable.body.Row1}>
                        <Text style={styles.resTable.body.Row1.Text}>
                          {el?.customer_name}
                        </Text>
                      </View>

                      <View style={styles.resTable.body.Row2}>
                        <Text style={styles.resTable.body.Row2.Text}>
                          {el?.phone_number}
                        </Text>
                      </View>

                      <View style={styles.resTable.body.Row3}>
                        <Text style={styles.resTable.body.Row3.Text}>
                          {el?.item_name}
                        </Text>

                        {el?.OrderItemAddOns &&
                          el?.OrderItemAddOns.map((addons) => (
                            <View style={styles.resTable.addons}>
                              <Text>
                                {addons?.option_group_name} (
                                {addons?.option_item_name}){" "}
                              </Text>
                            </View>
                          ))}

                        <Text style={styles.resTable.special_notes}>
                          {el?.special_notes}
                        </Text>
                      </View>

                      <View style={styles.resTable.body.Row5}>
                        <Text style={styles.resTable.body.Row5.Text}>
                          {el?.quantity}
                        </Text>
                      </View>

                      <View style={styles.resTable.body.Row6}>
                        <Text style={styles.resTable.body.Row6.Text}>
                          {el?.total}
                        </Text>
                      </View>

                      <View style={styles.resTable.body.Row7}>
                        <Text style={styles.resTable.body.Row7.Text}>
                          {el?.sub_total}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>

              <View style={styles.billdetails}>
                <Text>Bill Details</Text>
              </View>
              <View>
                <Text style={styles.line}></Text>
              </View>

              <View style={styles.PriceSection}>
                <View style={styles.totalPrice}>

                  {subOrderData?.order_type === "Takeaway" && (
                    <Text style={{ color: "red", fontSize: "15px", marginTop: "-12px" }}>
                      Order Type -{" "}
                      {subOrderData?.mode_of_payment == 7
                        ? "Cash on arrival"
                        : "Paid"}
                    </Text>
                  )}

                  {subOrderData?.order_type === "Delivery" && (
                    <Text style={{
                      color: "red",
                      fontSize: "15px",
                      marginTop: "-12px",
                    }}>
                      Order Type -{" "}
                      {subOrderData?.mode_of_payment == 5
                        ? "Cash on delivery"
                        : "Paid"}
                    </Text>
                  )}


                  <Text>Item Total - {subOrderData?.sub_total}</Text>
                  <Text>Tips - {subOrderData?.tip}</Text>
                  <Text>Discount - {subOrderData?.discount}</Text>

                  <Text>Loyalty Discount - {subOrderData?.loyalty_points}</Text>

                  <Text>Pending Payment - {subOrderData?.service_tax}</Text>

                  {subOrderData?.order_type === "Delivery" &&
                    <Text>Delivery fee - {subOrderData?.delivery_fee ?? 0}</Text>
                  }

                </View>

                <View style={styles.grandTotal}>

                  {subOrderData?.order_type === "Delivery" ?
                    <View style={styles.grandTotal}>
                      <Text>Total Amount - {parseFloat(subOrderData?.total) + parseFloat(subOrderData?.delivery_fee)}</Text>
                    </View>
                    :
                    <View style={styles.grandTotal}>
                      <Text>Total Amount - {subOrderData?.total}</Text>
                    </View>
                  }

                </View>
              </View>


              <View style={styles.billdetails}>
                <Text>Customer Address Detail</Text>
              </View>
              <View>
                <Text style={styles.line}></Text>
              </View>

              <View style={styles.PriceSection}>
                <View style={styles.totalPrice}>
                  <Text>House No - {subOrderData?.Address?.house_number}</Text>
                  <Text>Street - {subOrderData?.Address?.street}</Text>
                  <Text>City - {subOrderData?.Address?.city}</Text>
                  <Text>
                    Country - {subOrderData?.Address?.country}
                  </Text>
                </View>
              </View>



              {subOrderData?.OrderCarDetail && (
                <>
                  <View style={styles.billdetails}>
                    <Text>Car Details</Text>
                  </View>
                  <View>
                    <Text style={styles.line}></Text>
                  </View>

                  <View style={styles.PriceSection}>
                    <View style={styles.totalPrice}>
                      <Text>
                        Car Plate Number -{" "}
                        {subOrderData?.OrderCarDetail.car_number}
                      </Text>
                      <Text>
                        Car Type - {subOrderData?.OrderCarDetail.car_type}
                      </Text>
                      <Text>
                        Car Colour - {subOrderData?.OrderCarDetail.car_colour}
                      </Text>
                    </View>
                  </View>
                </>
              )}


              {subOrderData?.OrderOfficeDetail && (
                <>
                  <View style={styles.billdetails}>
                    <Text>Office/Room Details</Text>
                  </View>
                  <View>
                    <Text style={styles.line}></Text>
                  </View>

                  <View style={styles.PriceSection}>
                    <View style={styles.totalPrice}>
                      <Text>
                        Floor Number -{" "}
                        {subOrderData?.OrderOfficeDetail?.floor_number}
                      </Text>
                      <Text>
                        Office/Room Number -{" "}
                        {subOrderData?.OrderOfficeDetail?.room_number}
                      </Text>
                    </View>
                  </View>
                </>
              )}


            </Page>
          </Document>
        }
        fileName="somename.pdf"
      >
        {({ blob, url, loading, error }) => {
          getURL(blob, url, loading, error);
        }}
      </PDFDownloadLink>
    </>
  );
};

export default function CompletedPrintReceipt({
  open,
  handleClose,
  subOrderData,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [billReceipt, setBillReceipt] = useState(null);
  const getURL = (blob, url, loading, error) => {
    setBillReceipt(url);
  };
  const direction = useSelector((state) => state.languageDirection.direction);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
      >
        <Cancel onClick={handleClose} dir={direction}>
          <i className="icon-CloseCross" />
        </Cancel>

        <PrintReceiptWrapper>
          <div>
            <MyDoc subOrderData={subOrderData} getURL={getURL} />
          </div>

          <ButtonWrapper style={{ marginTop: "20px" }}>
            {billReceipt && (
              <>
                <BlackButtonMobile onClick={() => handleClose()}>
                  <IntlMessage id="button.close" />
                </BlackButtonMobile>
                <a href={billReceipt} target="_blank">
                  <OrangeButton>
                    <IntlMessage id="button.print" />
                  </OrangeButton>
                </a>
              </>
            )}
          </ButtonWrapper>
        </PrintReceiptWrapper>
      </Dialog>
    </div>
  );
}

const PrintReceiptWrapper = styled.div`
  width: 100%;
  min-width: 595px;
  height: 100vh;
  background: #fff;
  padding: 40px 10px 20px 10px;

  div {
    width: 100%;
    outline: 1px dashed #979797;
    padding: 5px;
  }

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }
`;

export const ButtonWrapper = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
  }
`;
